import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import Seo from "../../components/SeoComponent";
import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import SectionImage from "../../components/ui/SectionImage";
import ListText from "../../components/ui/ListText";
import SectionImagePlayer from "../../components/ui/SectionImagePlayer";
import SectionSubMenu from "../../components/ui/SectionSubMenu";
import Footer from "../../components/Footer";
// import ReadMore from "../../components/ReadMore";

import { EXPERTISES_SUBMENU } from "../../constants/constants";

const Aerospace = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("page-expertise.art.metadata.name")} description={t("page-expertise.art.metadata.description")} />
      <Header />
      <TitlePage category={t("page-expertise.art.category")} title={t("page-expertise.art.title")} />
      <SectionImage imageHD="art-event.jpg" imageMD="art-event-md.jpg" imageSD="art-event-sd.jpg" />
      <SectionTextPage>
        <p className="section-title">{t("page-expertise.art.s1-1")}</p>
        <p>{t("page-expertise.art.s1-p1")}</p>
        <p>{t("page-expertise.art.s1-p2")}</p>
        <p>{t("page-expertise.art.s1-p3")}</p>
        <p>{t("page-expertise.art.s1-p4")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-title">{t("page-expertise.art.s2-1")}</p>
        <p>{t("page-expertise.art.s2-p1")}</p>
        <p>{t("page-expertise.art.s2-p2")}</p>
      </SectionTextPage>

      <SectionTextPage removePaddingBottom="true">
        <p className="section-title">{t("page-expertise.art.s3-1")}</p>
        <p className="section-subtitle">{t("page-expertise.art.s3-2")}</p>
        <ListText text={t("page-expertise.art.s3-l1")} />
        <ListText text={t("page-expertise.art.s3-l2")} />
        <p className="section-subtitle">{t("page-expertise.art.s3-3")}</p>
        <ListText text={t("page-expertise.art.s3-l3")} />
        <ListText text={t("page-expertise.art.s3-l4")} />
        <ListText text={t("page-expertise.art.s3-l5")} />
        <ListText text={t("page-expertise.art.s3-l6")} />
        <ListText text={t("page-expertise.art.s3-l7")} />
        <ListText text={t("page-expertise.art.s3-l8")} />
        <ListText text={t("page-expertise.art.s3-l9")} />
      </SectionTextPage>

      <SectionImagePlayer
        imageHD="art-event-2.jpg"
        imageMD="art-event-2-md.jpg"
        imageSD="art-event-2-sd.jpg"
        href="https://youtu.be/NE5gXon1UYU"
        target="_blank"
      />

      <SectionSubMenu menus={EXPERTISES_SUBMENU} title={t("page-expertise.other-menu-title")} codeExclude="art" />

      {/* <ReadMore title={t("page-expertise.art.readmore")} news={readMore} /> */}

      <Footer />
    </>
  );
};

export default Aerospace;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
